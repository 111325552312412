import React, {useState, useEffect, useRef, Fragment, useContext} from 'react';
import axios from 'axios';
import { throttle, debounce } from "throttle-debounce";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import jwtDecode from 'jwt-decode';
import notificationSound from '../../../assets/sound/notification_sound.wav';
import ApiLoan from "../../../service/ApiLoanService";
import { listToObject, getLessNumberIndexThan, formatDateMessage } from '../../../utils/utils';
import { formatMessage, createNewMessageFromClient, updateShowedClients } from "./ConversationListHelpers"
import { MessageContext } from "../../../context/messageContext";
import { CompanyContext } from '../../../context/companyContext';
import { switchRole } from "../../../shared/roles";
import DialogGeneric from "../../../UI/DialogGeneric";
import CustomTabHeaders from '../../../UI/CustomTabHeaders';
import ConversationSearch from '../ConversationSearch';
import ConversationListItem from '../ConversationListItem';
import Toolbar from '../Toolbar';
import ToolbarButton from '../ToolbarButton';
import {Dropdown} from "primereact/dropdown";
import './ConversationList.scss';
import MessageNoteReminder from "../MessageNote/MessageNoteReminder";
import {MessageNoteContext} from "../../../context/messageNoteContext";
import {Toast} from "primereact/toast";
import { DateTime } from 'luxon';
import CustomSpinner from "../../../UI/CustomSpinner";
import { Menu } from 'primereact/menu';
import ConversationContact from "../ConversationContact/ConversationContact";
import {PHONE} from "../../../shared/contactTypes";
import { useParams } from "react-router-dom";
import { phone } from "phone";
import MessagesNoteModal from "../MessageNote/MessagesNoteModal";
import { nameMimetype } from '../../../utils/utils';
import ClientFormCusmon from '../../Clients/Client/ClientFormCustom';
import { Dialog } from 'primereact/dialog';
import InfiniteScroll from 'react-infinite-scroller';
import { useAudioPlayer } from '../../../context/AudioPlayerContext';
import Avatar from 'react-avatar';
import { Button } from 'primereact/button';
import ResultNotFount from '../../../UI/ResultNotFount';
import ConversationSkeleton from '../ConversationSkeleton';
import { getPhoneInfo } from '../../../shared/utility';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import ChannelSelector from '../../ChatChannelContainer/ChannelSelector';

function useMergeState(initialState) {
  const [state, setState] = useState(initialState);
  const setMergedState = (newState) => {
    return setState((prevState) => {
      let currentState = {
        conversations: newState.conversations,
        messagesByContact: newState.messagesByContact,
        searchLoading: newState.searchLoading,
        page: newState.page,
      }
      return currentState
    });
  }
  return [state, setMergedState];
}

const ConversationList = React.memo(function ConversationList(props) {
  const [conversationState, setConversationState] = useMergeState({
    conversations: [],
    messagesByContact: [],
    searchLoading: false,
    page: 1,
  });

  const [clientsState, setClientsState] = useState({
    showedClients: [],
    clients: [],
    searchLoading: false,
    page: 1,
  });
  const [paginating, setPaginating] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadinglist, setLoadinglist] = useState(false);
  const [loadingsearch, setLoadingsearch] = useState(false);
  const [lastMessageReached, setLastMessageReached] = useState(false);
  const [lastClientReached, setLastClientReached] = useState(false);
  const history = useHistory();
  const [selectedTab, setSelectedTab] = useState(0);
  const [assign, setAssign] = useState(false);
  const [notAssigned, setNotAssigned] = useState(false);
  const [showUnreads, setShowUnreads] = useState(false);
  const [filterUnreads, setFilterUnreads] = useState(false);
  const [showArchivedMessages, setArchivedMessages] = useState(false);
  const [filterArchivedMessages, setFilterArchivedMessages] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedUserTmp, setSelectedUserTmp] = useState([]);
  const [selectedTagTmp, setSelectedTagTmp] = useState([]);
  const [dialogCustomTag, setDialogCustomTag] = useState(false);
  const [dialogReassignMessages, setDialogReassignMessages] = useState(false);
  const [updateModalInfo, setUpdateModalInfo] = useState(false);
  const [valueDialog, setValueDialog] = useState(null);
  const [modalInfo, setModalInfo] = useState(null);
  const [selectedCustomTags, setSelectedCustomTags] = useState([]);
  const [dialogNoteReminder, setDialogNoteReminder] = useState(false);
  const apiService = new ApiLoan();
  const CancelTokenConversationRequest = axios.CancelToken;
  const conversationRequest = CancelTokenConversationRequest.source();
  const message_context = useContext(MessageContext);
  const company_context = useContext(CompanyContext);
  const [userClaims, setUserClaims] = useState([]);
  const [supervisor, setSupervisor] = useState(false);
  const [conversationListUsers, setConversationListUsers] = useState(null);
  const [conversationListUsersOrigin, setConversationListUsersOrigin] = useState(null);
  const [showConversationListUsers, setShowConversationListUsers] = useState(false);
  const [selectedUserList, setSelectedUserList] = useState(null);
  const [selectedTagList, setSelectedTagList] = useState(null);
  const [selectedTag, setSelectedTag] = useState(null);
  const [showConversationListTags, setShowConversationListTags] = useState(false);
  const [conversationActive, setConversationActive] = useState(null);
  const [conversationListTags, setConversationListTags] = useState(null);
  const [itemMenuFilter, setItemMenuFilter] = useState([]);
  const [itemMenuMore, setItemMenuMore] = useState(false);
  const [profile, setProfile] = useState(null);
  const [conversationListGroups, setConversationListGroups] = useState(null);
  const [conversationListGroupsOrigin, setConversationListGroupsOrigin] = useState(null);
  const socket = company_context.socket;
  const [showConversationListGroups, setShowConversationListGroups] = useState(null);
  const [selectedGroupList, setSelectedGroupList] = useState(null);
  const [selectedGroupTmp, setSelectedGroupTmp] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [showClientView, setShowClientView] = useState(false);
  const num_results = 40;
  const { setIsTopBarVisible } = company_context
  const [height, setHeight] = useState(window.innerWidth);
  const player = document.getElementById('globalAudioPlayer');
  const { audioEnded, setAudioEnded, isAudioVisible, setIsAudioVisible } = useAudioPlayer();
  const [ loadingConversationQuick, setLoadingConversationQuick ] = useState(false);

  const [searchConversationContactValue, setSearchConversationContactValue] = useState({
    phone_number: '',
    country_prefix: '',
    phone_valid: false
  });
  const [loadingSearchConversationContact, setLoadingSearchConversationContact] = useState(false);

  const toast = useRef(null);
  const refMenu = useRef(null);
  const showedClientsRef = useRef(clientsState.showedClients);
  const searchValueRef = useRef("");

  const historyParams = useParams();

  const {selectedClient, selectedMessageNote, globalClients, expirationNotes,
    messageNotes, errorNotes, loadingConversation, selectedContact,
    activeMessageNoteModal, clientFullName, updateMessageNotes, updateErrorNotes, updateExpirationNotes,
    updateLoadingConversation, setActiveMessageNoteModal} = useContext(MessageNoteContext);
    const selectedPhoneContact = useRef(null);

  useEffect(() => {
    setIsTopBarVisible(true)
    const apiService = new ApiLoan();
    apiService.getResources({
      url: '/profile'
    }).then(response => {
      setProfile(response.data);
    })
  }, []);

  useEffect(() => {
    showedClientsRef.current = clientsState.showedClients;
  }, [clientsState.showedClients]);

  useEffect(() => {
    searchValueRef.current = searchValue;
  }, [searchValue]);

  function sendNotificationSound(){
    const audio = new Audio(notificationSound);
    audio.addEventListener('canplaythrough', () => {
      audio.play();
    });
  }

  function showNotification(title, options, message) {
    if ('serviceWorker' in navigator && isMobile() && localStorage.getItem('token')) {
      if (Notification.permission === 'granted') {
        navigator.serviceWorker.ready.then(function(registration) {
          registration.showNotification(title, options);
        });
      }
    }
    if ('Notification' in window && !isMobile() && localStorage.getItem('token')) {
      if (Notification.permission === 'granted') {
        var notification = new Notification(title, options);
        notification.onclick = function(event) {
          event.preventDefault();
          message.client.name = message.client.firstname + ' ' + message.client.lastname;
          props.onSelectClientHandler(message.client);
          setConversationActive(message.client.id);
          window.focus();
        };
      };
    }
  }

  useEffect(() => {
    getMessagesByContactAll();
    getClients();
    return () => {
      conversationRequest.cancel()
    }
  }, [company_context.privateInbox, company_context.privateGroup]);

  const isMobile = () => {
    return window.innerWidth <= 750;
  };

  useEffect(() => {
    if (selectedTag) message_context.updateSelectedCustomTags([selectedTag])
    else message_context.updateSelectedCustomTags([])
  }, [selectedTag]);

  useEffect(() => {
    setConversationListTags(message_context.customTags);
  }, [message_context.customTags]);

  useEffect(() => {
    let newGroups = [];
    if(!company_context.privateInbox || ['admin', 'supervisor'].includes(switchRole())){
      newGroups = message_context.groups
    } else {
      newGroups = message_context.groups.filter(group => {
        return group.users.some(user => user.username === localStorage.getItem('username'));
      });
    }
    setConversationListGroups(newGroups);
    setConversationListGroupsOrigin(newGroups);
  }, [message_context.groups])

  useEffect(() => {
    const updateWindowDimensions = () => {
      const newHeight = window.innerWidth;
      setHeight(newHeight);
    };

    window.addEventListener("resize", updateWindowDimensions);

    return () => window.removeEventListener("resize", updateWindowDimensions);

  }, []);

  useEffect(() => {
    let selectedTags = []
    selectedTagTmp.map(element => {
      selectedTags.push(element.id);
    })
    setSelectedCustomTags(selectedTags);
  }, [selectedTagTmp]);

  useEffect(()=>{
    let items = [];
    let userFilter =  {
      label:
      <i>
          <FontAwesomeIcon icon={"fa-regular fa-user"} style={{width: '1.3em', height: '1.3em'}}/>
          <span className="menu-font-awesome-icon"> Filtro por operador </span>
      </i>,
      command: () => {
        filterConversationListByUser()
      }
    }
    let tagFilter = {
      label:
      <i>
          <FontAwesomeIcon icon={"fa-regular fa-tag"} style={{width: '1.3em', height: '1.3em'}}/>
          <span className="menu-font-awesome-icon"> Filtro por etiqueta </span>
      </i>,
      command: () => {
        filterConversationListByTag()
      }
    }
    let groupFilter = {
      label:
      <i>
          <FontAwesomeIcon icon={"fa-regular fa-users"} style={{width: '1.3em', height: '1.3em'}}/>
          <span className="menu-font-awesome-icon"> Filtro por grupo </span>
      </i>,
      command: () => {
        filterConversationListByGroup()
      }
    }
    let assigned = {
      label:
      <i>
          <FontAwesomeIcon icon={"fa-regular fa-user-check"} style={{width: '1.3em', height: '1.3em'}}/>
          <span className="menu-font-awesome-icon"> Filtrar asignado </span>
      </i>,
      command: () => {
        setSelectedTab(4);
      }
    }
    let notAssigned = {
      label:
      <i>
          <FontAwesomeIcon icon={"fa-regular fa-user-xmark"} style={{width: '1.3em', height: '1.3em'}}/>
          <span className="menu-font-awesome-icon"> Filtrar no asignado </span>
      </i>,
      command: () => {
        setSelectedTab(5);
      }
    }
    const role = switchRole();
    const isAudience = role === 'audience';
    const commonFilters = [tagFilter, groupFilter];

    if (supervisor || (isAudience && !company_context.privateGroup)){
      items.push(userFilter, ...commonFilters);
    } else {
      items.push(...commonFilters);
    }

    if (!company_context.privateInbox || ['admin', 'supervisor'].includes(role)) {
      items.push(...[assigned, notAssigned]);
    }
    setItemMenuFilter(items);
  },[company_context.privateInbox,company_context.privateGroup, supervisor])

  useEffect(() => {
    setLastClientReached(false);
    setLastMessageReached(false);
  }, [searchValue, selectedUser, showUnreads, showArchivedMessages, notAssigned]);

  useEffect(() => {
    socket.on('delete_message_reaction-' + localStorage.getItem('provider_id'), removeMessageReaction);
    socket.on('update_message_reaction-' + localStorage.getItem('provider_id'), updateMessage);
    socket.on('new_messages-' + localStorage.getItem('provider_id'), addNewMessageByContact);
    socket.on('messenger-events-' + localStorage.getItem('provider_id'), updateMessage);
    socket.on('add-custom-tags-to-client-' + localStorage.getItem('provider_id'), addClientCustomTags);
    socket.on('delete-custom-tag-' + localStorage.getItem('provider_id'), deleteClientCustomTag);
    socket.on('client-assignation-' + localStorage.getItem('provider_id'), assignClient);
    socket.on('client-removal-' + localStorage.getItem('provider_id'), removeClient);
    //socket.on('update-custom-tag-' + localStorage.getItem('provider_id'), updateClientCustomTag)
    socket.on('custom-tag-csv-' + localStorage.getItem('provider_id'), customTagsCSV);
    socket.on('new_note-' + localStorage.getItem('provider_id'), addNewNote);
    socket.on('delete_reminder-' + localStorage.getItem('provider_id'), deleteNoteReminder);
    socket.on('seen_reminder-' + localStorage.getItem('provider_id'), seenNoteReminder);
    socket.on('update_status_message-' + localStorage.getItem('provider_id'), updateStatusMessage);
    socket.on('update_message-' + localStorage.getItem('provider_id'), updateMessage);
    return () => {
        socket.off('delete_message_reaction-' + localStorage.getItem('provider_id'), removeMessageReaction);
        socket.off('update_message_reaction-' + localStorage.getItem('provider_id'), updateMessage);
        socket.off('new_messages-' + localStorage.getItem('provider_id'), addNewMessageByContact);
        socket.off('messenger-events-' + localStorage.getItem('provider_id'), updateMessage);
        socket.off('add-custom-tags-to-client-' + localStorage.getItem('provider_id'), addClientCustomTags);
        socket.off('delete-custom-tag-' + localStorage.getItem('provider_id'), deleteClientCustomTag);
        socket.off('client-assignation-' + localStorage.getItem('provider_id'), assignClient);
        socket.off('client-removal-' + localStorage.getItem('provider_id'), removeClient);
        //socket.off('update-custom-tag-' + localStorage.getItem('provider_id'), updateClientCustomTag)
        socket.off('custom-tag-csv-' + localStorage.getItem('provider_id'), customTagsCSV);
        socket.off('new_note-' + localStorage.getItem('provider_id'), addNewNote);
        socket.off('delete_reminder-' + localStorage.getItem('provider_id'), deleteNoteReminder);
        socket.off('seen_reminder-' + localStorage.getItem('provider_id'), seenNoteReminder);
        socket.off('update_status_message-' + localStorage.getItem('provider_id'), updateStatusMessage);
        socket.off('update_message-' + localStorage.getItem('provider_id'), updateMessage);
    }
  },[conversationState.messagesByContact, conversationState.conversations, globalClients]);


  useEffect(() => {
    if (updateModalInfo && valueDialog !== null && dialogCustomTag) {
      setModalInfo(<DialogGeneric visible={dialogCustomTag}
                                  withoutCancel={false}
                                  closable={false}
                                  setVisible={setDialogCustomTag}
                                  closeModal={true} {...valueDialog}  />);
    }
  }, [updateModalInfo, valueDialog, dialogCustomTag]);

  useEffect(() => {
    if (updateModalInfo && valueDialog !== null && dialogReassignMessages) {
      setModalInfo(<DialogGeneric visible={dialogReassignMessages}
                                  closable={false}
                                  setVisible={setDialogReassignMessages}
                                  closeModal={true} {...valueDialog}  />);
    }
  }, [updateModalInfo, valueDialog, dialogReassignMessages]);

  useEffect(() => {
    if (!dialogCustomTag && !dialogReassignMessages){
      props.setButtonsActivated(false);
      if (modalInfo !== null) {
        setModalInfo(null);
      }
      if (updateModalInfo){
        setUpdateModalInfo(false);
      }
      if (valueDialog !== null){
        setValueDialog(null);
      }
    }

  }, [modalInfo, dialogCustomTag, dialogReassignMessages]);

  useEffect(() => {
    let role = switchRole();
    if (role === 'supervisor') {
      setSupervisor(true);
    }
    getUsers();
  },[]);

  useEffect(() => {
    if (message_context.closeModal){
      setDialogCustomTag(!dialogCustomTag)
      message_context.updateCloseModal(false);
    }
  }, [message_context.closeModal]);

  useEffect(() => {
    if(!showUnreads){
      setFilterUnreads(false);
    }
  }, [showUnreads]);

  useEffect(() => {
    if (selectedClient !== null && globalClients) {
      setActiveMessageNoteModal(true);
      updateLoadingConversation(false);
    }
  }, [selectedClient, globalClients, lastMessageReached, selectedTab, conversationState.conversations]);

  useEffect(() => {
    if (errorNotes !== null && dialogNoteReminder){
      toast.current.show({severity: 'error', summary: 'Nota/Recordatorio', detail: errorNotes,
        sticky: true});
      updateErrorNotes(null);
    }
  }, [errorNotes, dialogNoteReminder]);


  useEffect(() => {
    if (searchConversationContactValue.phone_number === ''){
      setClientsState({
        ...clientsState,
        showedClients: [...clientsState.clients],
        page: 1
      })
    }
  }, [searchConversationContactValue.phone_number])

  useEffect(() => {
    if (loadingSearchConversationContact && !loading && !loadingsearch){
      if (clientsState.showedClients.length === 1){
        props.onSelectClientHandler(clientsState.showedClients[0].client);
        setConversationActive(clientsState.showedClients[0].client.id);
        setLoadingSearchConversationContact(false);
      } else if (clientsState.showedClients.length === 0){
        const apiService = new ApiLoan();
        let phone_number = searchConversationContactValue.country_prefix + searchConversationContactValue.phone_number
        apiService.postResource({
          url: '/debtors',
          data: {
            document_number: phone_number,
            firstname: phone_number,
            lastname: phone_number,
            cuil: phone_number,
            renamed: true,
            contacts:  [
                {
                  contact_type_id: PHONE,
                  value: {
                    country_prefix: searchConversationContactValue.country_prefix,
                    value: searchConversationContactValue.phone_number,
                    validate_contact: false
                  }
                }
            ]
          }
        }).then(response => {
          let contacts = [{
            client_id: response.data.contacts[0].client_id,
            contact_type_id: response.data.contacts[0].contact_type_id,
            country_prefix: response.data.contacts[0].country_prefix,
            value: response.data.contacts[0].value,
          }]
          let new_client = [{
            photo: 'assets/layout/images/profile.png',
            name: response.data.firstname,
            text: '',
            seen: true,
            client: {
              id: response.data.id,
              name: response.data.firstname,
              client_custom_tags: [],
              contacts: contacts,
            },
            client_id: response.data.id,
            id: response.data.id
          }];

          setClientsState({
            ...clientsState,
            showedClients: [
              ...new_client
            ],
            clients: [
              ...clientsState.clients,
              ...new_client
            ]
          })
          setSearchConversationContactValue((prevState) => ({
            ...prevState,
            phone_valid: true
          }));
        }).catch((error) => {
          setSearchConversationContactValue((prevState) => {
            return {
                ...prevState,
                phone_valid: false
            }
          });
          toast.current.show({severity: 'error', summary: 'Inicio de conversación rápida', detail: 'Numero inválido', sticky: true, life: 3000});
        }).finally(() => {setLoadingSearchConversationContact(false);});
      }
    }

  }, [loadingSearchConversationContact, clientsState.showedClients, conversationState.conversations])

  useEffect(() => {
    if (message_context.selectedPhoneContact && !loadingSearchConversationContact && !message_context.loadingSearchSelectedPhoneContact){
      setSearchConversationContactValue({
          phone_number: message_context.selectedPhoneContact.phone_number,
          country_prefix: message_context.selectedPhoneContact.country_prefix,
          phone_valid: true
      })
      message_context.updateLoadingSearchSelectedPhoneContact(true);
    }
  }, [message_context.selectedPhoneContact, loadingSearchConversationContact, message_context.loadingSearchSelectedPhoneContact])

  useEffect(() => {
    if (message_context.loadingSearchSelectedPhoneContact && searchConversationContactValue.phone_number !== ''){
      setLoadingConversationQuick(true);
      setTimeout(() => {
        searchConversationContactHandler();
        setLoadingConversationQuick(false);
      }, 3000);
      message_context.updateSelectedPhoneContact(null);
      message_context.updateLoadingSearchSelectedPhoneContact(false);
    }
  }, [message_context.loadingSearchSelectedPhoneContact, searchConversationContactValue.phone_number])

  const onSelectedPhoneContact = () => {
    let phoneInfo = getPhoneInfo(selectedPhoneContact?.current);
    let contact = {
        phone_number: selectedPhoneContact?.current || '',
        country_prefix: '',
        phone_valid: false,
    }

    if (phoneInfo){
        contact.country_prefix = phoneInfo.country_code;
        contact.phone_number = phoneInfo.number;
        contact.phone_valid = true
    }
    message_context.updateSelectedPhoneContact(contact);
    selectedPhoneContact.current = null;
    history.replace('/')
  }

  useEffect(() => {
    let { phone_number } = historyParams;

    if (phone_number !== undefined){
        selectedPhoneContact.current = phone_number;
        onSelectedPhoneContact();
        setSelectedTab(2);
    }

  }, [historyParams]);


  const getMessagesByContactAll = () => {
    setLoadingsearch(true);
    setLoading(true);
    getMessagesByContact(1).then(response => {
        const messagesByContacts = response.data.objects.map((message) => formatMessage(message));
        getMessagesByContactPipUp(1).then((response) => {
          const messagesByContactsPinup = response.data.objects.map((message) => formatMessage(message));
          setConversationState({
            ...conversationState,
            messagesByContact: messagesByContactsPinup.concat(messagesByContacts),
            conversations: messagesByContactsPinup.concat(messagesByContacts),
          })
          setLoadingsearch(false);
          setLoading(false);
        }).catch(error => {
          setLoadingsearch(false);
          setLoading(false);
        });
    }).catch(error => {
      setLoadingsearch(false);
      setLoading(false);
    });
  };

  const getMessagesByContactPipUp = (page) => {
    return apiService.getResources({
        url: '/conversations',
        page: page,
        results_per_page: num_results,
        cancelToken: conversationRequest.token,
        archived: showArchivedMessages,
        pinup_messages: true,
    });
  };

  const getMessagesByContact = (page) => {
    return apiService.getResources({
        url: '/conversations',
        page: page,
        results_per_page: num_results,
        cancelToken: conversationRequest.token,
        archived: showArchivedMessages,
        pinup_messages: false,
    });
  };

  const getMoreMessagesByContact = (page, fromId, showUnreads, notAssigned, showArchivedMessages) => {
    setLoadingsearch(true);
    setLoading(true);

    let params = {
      page: page,
      results_per_page: num_results,
      from_id: fromId,
      cancelToken: conversationRequest.token
    }

    if(notAssigned !== null){
      params['not_assigned'] = notAssigned;
    }

    if(showUnreads !== null){
      params['unread_messages'] = showUnreads;
    }

    if(showArchivedMessages !== null){
      params['archived'] = showArchivedMessages;
    }
    return apiService.getResources({
        url: '/conversations',
        ...params
    }).then(response => {
      const prevClientIds = conversationState.messagesByContact.map(
        (message) =>  {
          return message.client_id;
        }
      );

      const newMessages = response.data.objects.filter(
        (message) =>  {
          return !prevClientIds.includes(message.client_id);
        }
      );

      const messagesByContacts = newMessages.map((message) => formatMessage(message));

      let { page } = conversationState
      if(messagesByContacts){
        page = page + 1;
      }

      setConversationState({
        ...conversationState,
        messagesByContact: [
          ...conversationState.messagesByContact,
          ...messagesByContacts
        ],
        conversations: [
          ...conversationState.conversations,
          ...messagesByContacts
        ],
        page: page
      })
      setLoadingsearch(false);
      setLoading(false);

      if(showUnreads){
        setFilterUnreads(true);
      }

      return response;
    }).catch(error => {
      setLoadingsearch(false);
      setLoading(false);
    });
  };

  const getMessagesFiltered = (page, showUnreads, showArchivedMessages, searchQuery, searchCustomTags, selectedUser, conversationFilterRequest, clientCustomTags, currentConversationState, notAssigned, selectedGroup) => {
    setLoading(true);
    setLoadingsearch(true);
    let params = {
        page: page,
        results_per_page: num_results,
        search_query: searchQuery,
        cancelToken: conversationFilterRequest.token
    }

    if(notAssigned !== null){
      params['not_assigned'] = notAssigned;
    }

    if(showUnreads !== null){
      params['unread_messages'] = showUnreads;
    }

    if(showArchivedMessages !== null){
      params['archived'] = showArchivedMessages;
    }

    if(selectedUser !== null){
      params['selected_user'] = selectedUser;
    }

    if (selectedGroup !== null){
      params['selected_group'] = selectedGroup;
    }

    if(searchCustomTags !== null){
      params['filters'] = {custom_tags: searchCustomTags};
    }
    apiService.getResources({
        url: '/conversations',
        ...params
    }).then(response => {
        const filteredMessagesByContacts = response.data.objects.map((message) => formatMessage(message));

        setConversationState({
          ...conversationState,
          messagesByContact: [...currentConversationState.messagesByContact],
          conversations: [...filteredMessagesByContacts],

        })

        setLoading(false);
        setLoadingsearch(false);
        if(showUnreads){
          setFilterUnreads(true);
        }
    }).catch(() => {
        setLoadinglist(false);
        setLoading(false);
    });
  };

  const getMoreMessagesFiltered = (page, fromId, showUnreads, showArchivedMessages, searchQuery, selectedUser, searchCustomTags, notAssigned, selectedGroup) => {
    setLoading(true);
    setLoadinglist(true);
    let params = {
        page: page,
        results_per_page: num_results,
        from_id: fromId,
        search_query: searchQuery,
    }

    if(notAssigned !== null){
      params['not_assigned'] = notAssigned;
    }

    if(showUnreads !== null){
      params['unread_messages'] = showUnreads;
    }

    if(showArchivedMessages !== null){
      params['archived'] = showArchivedMessages;
    }

    if(selectedUser !== null){
      params['selected_user'] = selectedUser;
    }

    if (selectedGroup !== null){
      params['selected_group'] = selectedGroup
    }

    if(searchCustomTags !== null){
      params['filters'] = {custom_tags: searchCustomTags};
    }

    return apiService.getResources({
        url: '/conversations',
        ...params
    }).then(response => {
        const prevClientIds = conversationState.conversations.map(
          (message) =>  {
            return message.client_id;
          }
        );

        const newMessages = response.data.objects.filter(
          (message) =>  {
            return !prevClientIds.includes(message.client_id);
          }
        );

        const filteredMessagesByContacts = newMessages.map((message) => formatMessage(message));

        let { page } = conversationState;

        if(filteredMessagesByContacts){
          page = page + 1;
        }

        setConversationState({
          ...conversationState,
          conversations: [
            ...conversationState.conversations,
            ...filteredMessagesByContacts
          ],
          page: page,
        })
        setLoading(false);
        setLoadinglist(false);
        if(showUnreads){
          setFilterUnreads(true);
        }

        return response;
    }).catch(error => {
      setLoadinglist(false);
      setLoading(false);
    });
  };

  const getMessagesFilteredDebounced = useRef(debounce(500, getMessagesFiltered));
  const getMessagesFilteredThrottled = useRef(throttle(500, getMessagesFiltered));

  useEffect(() => {
    const CancelTokenFilterRequest = axios.CancelToken;
    const conversationFilterRequest = CancelTokenFilterRequest.source();
    if(notAssigned || showUnreads || showArchivedMessages || searchValue || selectedUser ||
        selectedCustomTags.length > 0 || selectedGroup){
      if(searchValue.length < 5){
        if(selectedTab !== 2){
          getMessagesFilteredThrottled.current(1, showUnreads, showArchivedMessages, searchValue, selectedCustomTags, selectedUser,
              conversationFilterRequest, message_context.clientCustomTags, {...conversationState, page: 1}, notAssigned, selectedGroup);
        } else {
          getClientsFilteredThrottled.current(searchValue, selectedCustomTags, selectedUser, selectedGroup,
              conversationFilterRequest, {...clientsState, page: 1}, notAssigned);
        }

      } else {
        if(selectedTab !== 2){
          getMessagesFilteredDebounced.current(1, showUnreads, showArchivedMessages, searchValue, selectedCustomTags, selectedUser,
              conversationFilterRequest, message_context.clientCustomTags, {...conversationState, page: 1}, notAssigned, selectedGroup)
        } else {
          getClientsFilteredDebounced.current(searchValue, selectedCustomTags, selectedUser, selectedGroup,
              conversationFilterRequest, {...clientsState, page: 1}, notAssigned);
        }
      }
    }
    else {
      setConversationState({
        ...conversationState,
        conversations: [...conversationState.messagesByContact],
        page: 1
      })
      setClientsState({
        ...clientsState,
        showedClients: [...clientsState.clients],
        page: 1
      })
    }
    return () => {
      conversationFilterRequest.cancel()
      setTimeout(function(){
        setLoadingsearch(false);
     }, 2000);
    }
  },[showUnreads, showArchivedMessages, searchValue, selectedUser, selectedTab, selectedCustomTags, notAssigned, selectedGroup, searchConversationContactValue]);

  const getClients = () => {
    let url = '/recipients'
    let privateInbox = (!company_context.privateInbox || ['admin', 'supervisor'].includes(switchRole())) ? true : false;
    if (!privateInbox) {
      url = '/recipients?username=' + localStorage.getItem('username')
    }
    apiService.getResources({
        url: url,
        results_per_page: num_results,
    }).then(response => {
        const clients = response.data.objects.map((client) => {
          let newMessage = createNewMessageFromClient(client)
          return newMessage
        })
        setClientsState({
          ...clientsState,
          showedClients: clients,
          clients: clients
        })
    });
  };

  const getMoreClients = (fromId) => {
    let url = '/recipients'
    let privateInbox = (!company_context.privateInbox || ['admin', 'supervisor'].includes(switchRole())) ? true : false;
    if (!privateInbox) {
      url = '/recipients?username=' + localStorage.getItem('username')
    }

    let params = {
      url: url,
      results_per_page: num_results,
      from_id: fromId,
    }

    if(selectedUser){
      params['selected_user'] = selectedUser;
    }

    if (selectedGroup){
      params['selected_group'] = selectedGroup;
    }

    return apiService.getResources({
      ...params
    }).then(response => {
        const clients = response.data.objects.map((client) => {
          let newMessage = createNewMessageFromClient(client)
          return newMessage
        })
        setClientsState({
          ...clientsState,
          showedClients: [
            ...clientsState.showedClients,
            ...clients
          ],
          clients: [
            ...clientsState.clients,
            ...clients
          ]
        })

        return response;
    });
  };

  const getClientsFiltered = (searchQuery, searchCustomTags, selectedUser, selectedGroup, recipientFilterRequest, currentState) => {
    let url = '/recipients'
    let privateInbox = (!company_context.privateInbox || ['admin', 'supervisor'].includes(switchRole())) ? true : false;
    if (!privateInbox) {
      url = '/recipients?username=' + localStorage.getItem('username')
    }

    let params = {
        url: url,
        results_per_page: num_results,
        search_query: searchQuery,
        cancelToken: recipientFilterRequest.token
    }

    if(searchCustomTags !== null){
      params['filters'] = {custom_tags: searchCustomTags};
    }

    if(selectedUser){
      params['selected_user'] = selectedUser;
    }

    if (selectedGroup){
      params['selected_group'] = selectedGroup;
    }

    apiService.getResources({
        url: url,
        ...params
    }).then(response => {
        const filteredClients = response.data.objects.map((client) => {
          let newMessage = createNewMessageFromClient(client)
          return newMessage
        })

        setClientsState({
          ...currentState,
          showedClients: filteredClients,
        })
    }).catch(()=>{
      return;
    });
  };

  const getClientsFilteredThrottled = useRef(throttle(600, getClientsFiltered));
  const getClientsFilteredDebounced = useRef(debounce(500, getClientsFiltered));

  const getMoreClientsFiltered = (searchQuery, fromId) => {

    let url = '/recipients';
    let privateInbox = (!company_context.privateInbox || ['admin', 'supervisor'].includes(switchRole())) ? true : false;
    if (!privateInbox) {
      url = '/recipients?username=' + localStorage.getItem('username')
    }

    let params = {
      url: url,
      results_per_page: num_results,
      search_query: searchQuery,
      from_id: fromId,
    }

    if(selectedUser){
      params['selected_user'] = selectedUser;
    }

    if (selectedGroup){
      params['selected_group'] = selectedGroup;
    }

    return apiService.getResources({
        ...params
    }).then(response => {
        const filteredClients = response.data.objects.map((client) => {
          let newMessage = createNewMessageFromClient(client)
          return newMessage
        })

        setClientsState({
          ...clientsState,
          showedClients: [
            ...clientsState.showedClients,
            ...filteredClients
          ]
        })

        return response;
    });
  };

  //TODO: add new client if it matches with filters
  const addNewMessageByContact = (message) => {
    message = formatDateMessage(message);
    let notifyMessage = true;
    let privateInbox = false;

    if (company_context.privateInbox){
      if (!['admin', 'supervisor'].includes(switchRole())){
        privateInbox = true;
      }
    }

    if (message.reaction){
      return null;
    }

    if (privateInbox){
      if (message?.client?.conversation?.group !== null){
        if (company_context.privateGroup){
          if (message?.client?.conversation?.user.username !== localStorage.getItem('username')){
            notifyMessage = false;
          }
        } else {
          if (message?.client?.conversation !== null){
            let userInGroup = conversationListGroupsOrigin.filter(x => x.id === message?.client?.conversation?.group?.id);
            if (userInGroup.length === 0){
              notifyMessage = false;
            }
          } else {
            notifyMessage = false;
          }
        }
      } else {
        if (message?.client?.conversation?.user?.username !== localStorage.getItem('username')){
          notifyMessage = false;
        }
      }
    }

    if (selectedCustomTags.length > 0){
      if (message.client.client_custom_tags.length > 0){
        if (!message.client.client_custom_tags.some(r => selectedCustomTags.includes(r.custom_tag.id))){
          notifyMessage = false;
        }
      }
    }

    if (notifyMessage) {
      let newMessagesByContact = [...conversationState.messagesByContact]
      let newConversations = [...conversationState.conversations]
      let conversationsListHasClient = false

      const newMessage = formatMessage(message);
      if (message?.seen === false) {
        const body = message.mime_type !== null ? nameMimetype(message.mime_type) : message.emoji;
        if (profile?.notification_available) showNotification(
          message?.client?.firstname + ' ' + message?.client?.lastname,
          { body: "Nuevo Mensaje\n\n" + body, icon: `${process.env.REACT_APP_PAGE}/favicon.ico` },
          message,);
        if (profile?.sound_available && localStorage.getItem('token')) sendNotificationSound();
      };

      let cleanedConversations = [...newConversations]

      let cleanedMessagesByContact = newMessagesByContact.filter((msg) => {

        if (msg.client_id !== newMessage.client_id){
            return msg
        }
      })
      cleanedMessagesByContact.unshift(newMessage)

      cleanedConversations = newConversations.filter((msg) => {
        if (msg.client_id !== newMessage.client_id){
            return msg
        }else{
          conversationsListHasClient=true
        }
      })

      if (searchValue || selectedUser) {
        if (conversationsListHasClient) {
          cleanedConversations.unshift(newMessage)
        }
      } else if (selectedGroup) {
        const isSelectedGroupMatch = (selectedGroup && message.client.conversation?.group?.id === selectedGroup) ? true : false;
        if (isSelectedGroupMatch) {
          cleanedConversations.unshift(newMessage)
        }
      } else {
        cleanedConversations.unshift(newMessage)
      }

      setConversationState({
        ...conversationState,
        conversations: [...cleanedConversations],
        messagesByContact: [...cleanedMessagesByContact]
      })
    }
  }

  const assignClient = (message) => {
    const { messagesByContact, conversations } = conversationState;

    let messagesByContactIndexedByClientId = listToObject(messagesByContact, 'client_id');
    let conversationsIndexedByClientId = listToObject(conversations, 'client_id');

    const formatedMessage = formatMessage(message);

    if (!messagesByContactIndexedByClientId[formatedMessage.client_id]) {
      const position = getLessNumberIndexThan(message.id, messagesByContact.map(m => m.id));
      messagesByContact.splice(position, 0, formatedMessage);
    }

    if (!searchValue && !selectedUser && selectedCustomTags.length === 0){
      if (!conversationsIndexedByClientId[formatedMessage.client_id]) {
        const position = getLessNumberIndexThan(message.id, conversations.map(m => m.id));
        conversations.splice(position, 0, formatedMessage);
      }
    }

    setConversationState({
      ...conversationState,
      conversations: conversations,
      messagesByContact: messagesByContact
    });
  }

  const removeClient = (message) => {
    const { messagesByContact, conversations } = conversationState;

    const filteredConversations = conversations.filter((m) => m.client_id !== message.client_id);
    const filteredMessagesByContacts = messagesByContact.filter((m) => m.client_id !== message.client_id);

    setConversationState({
      ...conversationState,
      conversations: filteredConversations,
      messagesByContact: filteredMessagesByContacts,
    });
  }

  const removeMessageReaction = (message) => {
    const updatedMessagesByContact = conversationState.messagesByContact.map(messageToUpdate => {
      return messageToUpdate.id === message.id
        ? { ...messageToUpdate, body: message.parent.body, text: message.parent.body }
        : messageToUpdate;
    });

    const updatedConversations = conversationState.conversations.map(messageToUpdate => {
      return messageToUpdate.id === message.id
        ? { ...messageToUpdate, body: message.parent.body, text: message.parent.body }
        : messageToUpdate;
    });

    setConversationState({
      ...conversationState,
      messagesByContact: updatedMessagesByContact,
      conversations: updatedConversations
    });
  };

  const updateMessage = (message) => {

    let privateInbox = false;
    let privateGroup = company_context.privateGroup;

    if (company_context.privateInbox){
      if (!['admin', 'supervisor'].includes(switchRole())){
        privateInbox = true;
      }
    }

    let notifyMessage = true;

    if (privateInbox){
      if (message.client.conversation?.group !== null){
        if (privateGroup){
          if (message.client.conversation?.user.username !== localStorage.getItem('username')){
            removeClient(message);
            notifyMessage = false;
          }
        } else {
          if (message.client.conversation !== null){
            let userInGroup = conversationListGroupsOrigin.filter(x => x.id === message.client.conversation?.group?.id);
            if (userInGroup.length === 0){
              removeClient(message);
              notifyMessage = false;
            }
          } else {
            removeClient(message);
            notifyMessage = false;
          }
        }

      } else {
        if (message.client.conversation.user.username !== localStorage.getItem('username')){
          removeClient(message);
          notifyMessage = false;
        }
      }
    }

    if (!notifyMessage){
      return;
    }

    const { messagesByContact, conversations } = conversationState;

    let updatedMessagesByContact = [...messagesByContact];
    let updatedConversations = [...conversations];

    let messagesByContactIndexedByClientId = listToObject(messagesByContact, 'client_id');
    let conversationsIndexedByClientId = listToObject(conversations, 'client_id');

    updatedConversations.map((element) => element.client_id === message.client_id ? message.created = element.created : message.created = message.created);
    const formatedMessage = formatMessage(message);

    if (messagesByContactIndexedByClientId[formatedMessage.client_id]) {
      updatedMessagesByContact = messagesByContact.map((c) => (c.client_id === formatedMessage.client_id ? formatedMessage : c));
    }

    if (conversationsIndexedByClientId[formatedMessage.client_id]) {
      updatedConversations = conversations.map(c => c.client_id === formatedMessage.client_id ? formatedMessage : c);
    }
    if (!searchValue) {
      if (!conversationsIndexedByClientId[formatedMessage.client_id]) {
        const position = getLessNumberIndexThan(formatedMessage.id, conversations.map(m => m.id));
        updatedConversations.splice(position, 0, formatedMessage);
      }
    }

    const clientCustomTagIds = message.client.client_custom_tags.map(ct => ct.custom_tag.id)
    const selectedCustomTagIds = selectedCustomTags
    if (selectedCustomTags.length > 0 && message.client.client_custom_tags.length > 0 &&
        !selectedCustomTagIds.every(ct => clientCustomTagIds.includes(ct))) {
      updatedConversations = conversations;
    }

    if(selectedTab === 2){
      const currentShowedClients = showedClientsRef.current
      let updatedShowedClients = updateShowedClients(formatedMessage.client, currentShowedClients, searchValueRef.current)
      setClientsState({
        ...clientsState,
        showedClients: updatedShowedClients
      })
    }

    setConversationState({
      ...conversationState,
      conversations: updatedConversations,
      messagesByContact: updatedMessagesByContact
    })
  }

  const addClientCustomTags = ({client}) => {
    const client_id = client.id;

    let conversations = [...conversationState.conversations];
    let client_filtered = conversations.filter(x => x.client_id === client.id)[0];

    if (client_filtered !== undefined){
      client_filtered.client.client_custom_tags = client.client_custom_tags;
    }
    let client_filtered_index = conversations.findIndex(x => x.client_id === client_id);

    if (client_filtered_index !== -1){
      conversations[client_filtered_index] = client_filtered;
    }

    if(selectedTab === 2){
      const currentShowedClients = showedClientsRef.current
      let updatedShowedClients = updateShowedClients(client, currentShowedClients)
      setClientsState({
        ...clientsState,
        showedClients: updatedShowedClients
      })
    }

    setConversationState( {
        ...conversationState,
        conversations: [...conversations]
    });
  }

  const deleteClientCustomTag = (custom_tag) => {
    let conversations = [...conversationState.conversations];

    conversations = conversations.map(conversation => {
      conversation.client.client_custom_tags = conversation.client.client_custom_tags.filter(x => x.custom_tag.id !== custom_tag.id);
      return conversation;
    })

    let selected_tags = selectedCustomTags;

    if (selected_tags.includes(custom_tag.id)) {
      selected_tags = selected_tags.filter(x => x !== custom_tag.id);
      message_context.updateSelectedCustomTags(selected_tags);
    }

    setConversationState( {
      ...conversationState,
      conversations: conversations
    });
  }


  const customTagsCSV = ({clients}) => {
    if (clients.length > 0){
      let conversations = [...conversationState.conversations];

      clients.map(client => {
        let client_id = client.id;

        let client_info = conversations.filter(x => x.client_id === client_id)[0];
        if (client_info !== undefined){
          client_info.client.client_custom_tags = client.client_custom_tags;
        }

        let client_info_index = conversations.findIndex(x => x.client_id === client_id);

        if (client_info_index !== -1){
          conversations[client_info_index] = client_info;

          setConversationState( {
            ...conversationState,
            conversations: [...conversations]
          });
        }
        return client;
      });
    }
  }

  const addNewNote = (message) => {
    let allowed_message_types = ['note_reminder', 'scheduled_message'];

    if (!allowed_message_types.includes(message.message_type.name)){
      return null;
    }
    let notifyMessage = true;
    let privateInbox = false;

    if (company_context.privateInbox){
      if (!['admin', 'supervisor'].includes(switchRole())){
        privateInbox = true;
      }
    }
    if (privateInbox){
      if (message?.client?.conversation?.group !== null){
        if (company_context.privateGroup){
          if (message?.client?.conversation?.user.username !== localStorage.getItem('username')){
            notifyMessage = false;
          }
        } else {
          if (message?.client?.conversation !== null){
            let userInGroup = conversationListGroupsOrigin.filter(x => x.id === message?.client?.conversation?.group?.id);
            if (userInGroup.length === 0){
              notifyMessage = false;
            }
          } else {
            notifyMessage = false;
          }
        }
      } else {
        if (message?.client?.conversation?.user?.username !== localStorage.getItem('username')){
          notifyMessage = false;
        }
      }
    }

    if (notifyMessage) {
      let messageNoteKey = message.message_type.name === 'scheduled_message' ? 'scheduled_messages' : 'note_reminders';

      let message_notes_temp = [...messageNotes[messageNoteKey]]

      message_notes_temp.unshift(message);

      updateMessageNotes(prevState => {
        return {
          ...prevState,
          [messageNoteKey]: message_notes_temp
        }
      })
    }

  }

  const deleteNoteReminder = (message) => {
    if (!globalClients) {
      return null;
    }

    let allowed_message_types = ['note_reminder', 'scheduled_message'];
    if (!allowed_message_types.includes(message.message_type.name)){
      return null;
    }

    let messageNoteKey = message.message_type.name === 'scheduled_message' ? 'scheduled_messages' : 'note_reminders';
    let updated_messages = messageNotes[messageNoteKey].filter(x => x.id !== message.id);

    updateMessageNotes(prevState => {
      return {
        ...prevState,
        [messageNoteKey]: updated_messages
      }
    })

    setDialogNoteReminder(false);

    toast.current.show({severity: 'success', summary: 'Recordatorio', detail: 'Se elimino el recordatorio',
      sticky: true});
  }

  const seenNoteReminder = (message) => {
    if (!globalClients) {
      return null;
    }

    let messageNoteKey = message.message_type.name === 'scheduled_message' ? 'scheduled_messages' : 'note_reminders';
    let updated_messages = messageNotes[messageNoteKey].filter(x => x.id !== message.id);

    updateMessageNotes(prevState => {
      return {
        ...prevState,
        [messageNoteKey]: updated_messages
      }
    })

    setDialogNoteReminder(false);
    updateExpiredNoteReminders(updated_messages);
    toast.current.show({severity: 'success', summary: 'Recordatorio', detail: 'Se marcó como visto el recordatorio',
      sticky: true});
  }

  const updateStatusMessage = ({message, status}) => {
    const client_id = message.client_id;

    let conversations = [...conversationState.conversations];
    let conversation_filtered = conversations.filter(x => x.client_id === client_id)[0];

    if (conversation_filtered !== undefined){
      conversation_filtered[status] = true;
    }
    let conversation_filtered_index = conversations.findIndex(x => x.client_id === client_id);

    if (conversation_filtered_index !== -1){
      conversations[conversation_filtered_index] = conversation_filtered;
    }

    setConversationState( {
      ...conversationState,
      conversations: [...conversations]
    });
  }

  const updateExpiredNoteReminders = (notes) => {
    const dateNow = DateTime.now().toFormat('dd/MM/yyyy');

    let remindersExpired = notes.filter(x => {
      const reminderDate = DateTime.fromFormat(x.note_reminder.reminder_date, 'yyyy-MM-dd HH:mm:ss');
      return reminderDate.toFormat('dd/MM/yyyy') === dateNow;
    });

    updateExpirationNotes(remindersExpired.length);

  }

  const filterConversationList = (value) => {
    setSearchValue(value)
  }

  const filterConversationListByUser = () => {
      if (!supervisor){
        const token = localStorage.getItem('token');
        const decoded_jwt = jwtDecode(token);
        setSelectedUser(decoded_jwt.user_claims.user_id)
        setSelectedUserTmp([userClaims]);
        props.setButtonsActivated(true);
      } else {
        setShowConversationListUsers(!showConversationListUsers);
      }
  }

  const filterConversationListByTag = () => {
      setShowConversationListTags(!showConversationListTags);
  }

  const filterConversationListByGroup = () => {
      setShowConversationListGroups(!showConversationListGroups);
  }

  const userListDropDown = showConversationListUsers ? (
      <Fragment>
        <div className='list-drop'>
          <Dropdown value={selectedUserList} optionLabel="username" options={conversationListUsers}
                    onChange={(e) => setSelectedUserListHandler(e)} filter={!isMobile()} placeholder="Seleccionar Operador"/>
        </div>
      </Fragment>
  ) : null;

  const tagListDropDown = showConversationListTags ? (
    <Fragment>
      <div className='list-drop'>
        <Dropdown value={selectedTagList} optionLabel="name" options={conversationListTags}
                  onChange={(e) => setSelectedTagListHandler(e)} filter={!isMobile()} placeholder="Seleccionar Etiqueta"/>
      </div>
    </Fragment>
  ) : null;

  const groupListDropDown = showConversationListGroups ? (
      <Fragment>
        <div className='list-drop'>
          <Dropdown value={selectedGroupList} optionLabel="name" options={conversationListGroups}
                    onChange={(e) => setSelectedGroupListHandler(e)} filter={!isMobile()} placeholder="Seleccionar Grupo"/>
        </div>
      </Fragment>
  ) : null;

const setSelectedUserListHandler = (e) => {
  setSelectedUserTmp([e.value])
  setSelectedUser(e.value.id)
  setConversationListUsers(conversationListUsersOrigin.filter((element => element.id !== e.value.id)))
  setSelectedUserList(null)
  setShowConversationListUsers(null)
}

const setSelectedTagListHandler = (e) => {
  setSelectedTagTmp([...selectedTagTmp, e.value])
  setSelectedTag(e.value.id)

  setConversationListTags(conversationListTags.filter((element => element.id !== e.value.id)))
  setSelectedTagList(null);
  setShowConversationListTags(null);

}

const setSelectedGroupListHandler = (e) => {
  setSelectedGroupTmp([e.value]);
  setSelectedGroup(e.value.id);
  setConversationListGroups(conversationListGroupsOrigin.filter((element => element.id !== e.value.id)));
  setSelectedGroupList(null);
  setShowConversationListGroups(null);
}

const userFilter = selectedUserTmp.map((user, index) => {

  let add_title = user.name;

  return (
      <Fragment key={index}>
          <span className="custom-tags uno" data-tip={add_title}
            style={{
                marginRight: '0.1em',
                marginLeft: '0.4em',
                marginBottom: '0.1em'
            }}><FontAwesomeIcon icon={'user'}></FontAwesomeIcon>{user?.username ? ' ' + user.username + ' ' : ' Tú '}<i onClick={()=>onUserFilter(user)} style={{fontSize: '0.8rem', cursor: 'pointer'}} className="pi pi-times"></i>
          </span>
      </Fragment>
  );
});

const assignedUserFilter = (
  selectedTab === 4 && (
    <Fragment>
      <span className="custom-tags uno"
          style={{
          marginRight: '0.1em',
          marginLeft: '0.4em',
          marginBottom: '0.1em'
        }}><FontAwesomeIcon icon={'user'}></FontAwesomeIcon> asignado <i onClick={()=>setSelectedTab(0)} style={{fontSize: '0.8rem', cursor: 'pointer'}} className="pi pi-times"></i>
      </span>
    </Fragment>
  )
);

const notAssignedUserFilter = (
  selectedTab === 5 && (
    <Fragment>
      <span className="custom-tags uno"
          style={{
          marginRight: '0.1em',
          marginLeft: '0.4em',
          marginBottom: '0.1em'
        }}><FontAwesomeIcon icon={'user'}></FontAwesomeIcon> no asignado <i onClick={()=>setSelectedTab(0)} style={{fontSize: '0.8rem', cursor: 'pointer'}} className="pi pi-times"></i>
      </span>
    </Fragment>
  )
);

const tagFilter = selectedTagTmp.map((tag, index) => {

  return (
      <Fragment key={index}>
          <span className="custom-tags dos"
            style={{
                marginRight: '0.1em',
                marginLeft: '0.4em',
                marginBottom: '0.1em',
                backgroundColor: tag.color
            }}><FontAwesomeIcon icon={'fa-regular fa-tag'}></FontAwesomeIcon>{' ' + tag.name + ' '}<i onClick={()=>onTagFilter(tag)} style={{fontSize: '0.8rem', cursor: 'pointer'}} className="pi pi-times"></i>
          </span>
      </Fragment>
  );
});

const groupFilter = selectedGroupTmp.map((group, index) => {
  let add_title = group.name;

  return (
      <Fragment key={index}>
          <span className="custom-tags uno" data-tip={add_title}
            style={{
                marginRight: '0.1em',
                marginLeft: '0.4em',
                marginBottom: '0.1em'
            }}>
            <FontAwesomeIcon icon={'user-group'}></FontAwesomeIcon>{' ' + group.name + ' '}
            <i onClick={()=>onGroupFilter(group)}
               style={{fontSize: '0.8rem', cursor: 'pointer'}} className="pi pi-times">
            </i>
          </span>
      </Fragment>
  );
});

const onTagFilter = (tag) => {
  setSelectedTagTmp(selectedTagTmp.filter((element => element.id !== tag.id)))
  setConversationListTags([...conversationListTags, tag])
  setSelectedTag(null)
}

const onUserFilter = (user) => {
  setSelectedUserTmp(selectedUserTmp.filter((element => element.id !== user.id)))
  setConversationListUsers([...conversationListUsers, user])
  setSelectedUser(null)
}

const onGroupFilter = (group) => {
  setSelectedGroupTmp(selectedGroupTmp.filter((element => element.id !== group.id)))
  setConversationListGroups([...conversationListGroups, group]);
  setSelectedGroup(null)
}

const getUsers = () => {
  apiService.getResources({
    url: '/conversations/users',
  }).then(response => {
    let response_data = response.data.objects;
    const token = localStorage.getItem('token');
    const decoded_jwt = jwtDecode(token);
    setUserClaims(...response_data.filter((element => element.id === decoded_jwt.user_claims.user_id)));
    setConversationListUsers(response_data);
    setConversationListUsersOrigin(response_data);
  });
};

const getDialogParams = (action, id) => {
  let data = {};

  switch (action){
    case 'list':
      data['width'] = {'width': 'auto', 'min-width': '30vw'};
      data['height'] = {'height': 'auto', 'min-height': '20vw'};
      data['title'] = 'Etiquetas Guardadas';
      data['url'] = 'URL';
      data['method'] = 'post';
      data['fields'] = [{
        name: 'custom_tags',
        elementType: 'customTags',
        elementConfig: {},
        elementClass: 'col-12 md-12',
        value: '',
      }];
      break;
    case 'new':
      data['width'] = {'width': 'auto', 'min-width': '30vw'};
      data['height'] = {'height': 'auto', 'min-height': '20vw'};
      data['title'] = 'Crear Etiqueta';
      data['url'] = '/custom_tags';
      data['method'] = 'post';
      data['fields'] = [{
        name: 'name',
        elementType: 'text',
        elementConfig: {
          traduction: 'Titulo'
        },
        elementClass: 'col-12 md-12 mt-1',
        value: '',
      },
      {
        name: 'color',
        elementType: 'color',
        elementConfig: {
          traduction: 'Color'
        },
        elementClass: 'col-12 md-12 mt-1',
        value: '',
      },
      {
        name: 'custom_tag_list',
        elementType: 'customTagList',
        elementConfig: {
          traduction: 'Titulo'
        },
        dependence_of: ['name'],
        elementClass: 'col-12 md-12 mt-1',
      }];
      break;
    case 'reassign_messages':
      data['width'] = {'width': 'auto', 'min-width': '30vw'};
      data['height'] = {'height': 'auto', 'min-height': '20vw'};
      data['title'] = 'Transferir Chats';
      data['url'] = '/conversations/reassign';
      data['method'] = 'post';
      data['fields'] = [{
        name: 'reassign_conversations',
        elementType: 'reassignMessages',
        elementConfig: {
          traduction: 'Titulo',
          value: ''
        },
        elementClass: 'col-12 md-12 mt-1',
        value: {
          assign_from: null,
          assign_to: null
        }
      }];
      break
    default:
      break;
  }
  return data;
}

const getHeaderIcons = (action, close_event) => {
  let add_new_tag = null;
  let role = switchRole();

  if (role !== 'customer_support'){
    add_new_tag = action === 'list' && (
        <Button
          className="ml-auto"
          label="Nuevo"
          icon='pi pi-plus'
          onClick={() => dialogCustomTags('new', null)}
        />
    );
  }

  const close_dialog = (
    <button type="button" onClick={(e) => close_event(false)} class="p-dialog-header-icon p-dialog-header-close p-link" aria-label="Close">
      <span class="p-dialog-header-close-icon pi pi-times" aria-hidden="true"></span>
    </button>
  );

  return (
      <div className="flex">
        {add_new_tag}
        {close_dialog}
      </div>
  );
}

const getHeaderDialog = (title, action) => {
  const get_back = action === 'new' && (
    <span className="custom-tag-get-back" onClick={(e) => dialogCustomTags('list', null)}>
      <FontAwesomeIcon icon={'arrow-left'}/>
    </span>
  );

  return (
    <div className="p-dialog-title">
      <span>
        {get_back}
        <b>{title}</b>
      </span>
    </div>
  );

}

const getDisplayFooterDialog = (action) => {
  let show_footer = false;
  if (action === 'new') {
    show_footer = true
  }
  return show_footer
}

const dialogCustomTags = (action, id) => {
  setDialogCustomTag(true);
  const params = getDialogParams(action, id);
  let value_dialog = {
    'url': params.url,
    'method': params.method,
    'header': getHeaderDialog(params.title, action),
    'icons': getHeaderIcons(action, setDialogCustomTag),
    'show_footer': getDisplayFooterDialog(action),
    'submit_label': 'Crear',
    'sumaryToast': 'Etiquetas',
    'fields': params.fields,
    'width': params.width,
    'height': params.height,
    'buttons_rounded': true
  }
  if (action === 'new'){
    value_dialog['detailToast'] = 'Etiqueta guardada';
  } else if (action === 'list') {
    value_dialog['detailToast'] = 'Etiqueta modificada';
  }

  setUpdateModalInfo(true);
  setValueDialog(value_dialog);

}

  const dialogReassignConversation = (action, id) => {
    setDialogReassignMessages(true);
    setDialogCustomTag(false);
    const params = getDialogParams(action, id);
    let value_dialog = {
      'url': params.url,
      'method': params.method,
      'header': getHeaderDialog(params.title, action),
      'icons': getHeaderIcons(action, setDialogReassignMessages),
      'show_footer': true,
      'submit_label': 'Crear',
      'sumaryToast': 'Transferir Chats',
      'fields': params.fields,
      'width': params.width,
      'height': params.height,
      'buttons_rounded': true,
      'detailToast': 'Se transfirieron los Chats'
    }

    setUpdateModalInfo(true);
    setValueDialog(value_dialog);
  }

const onChangeFilterHandler = (event) => {
  filterConversationList(event.target.value);
}

const onClearValue = () => {
  filterConversationList('');
}

const handleScroll = () => {
  if(!paginating && !loading && conversationState.conversations.length > 0){

    const lastMessage = conversationState.conversations[conversationState.conversations.length - 1]
    const lastClient = clientsState.showedClients[clientsState.showedClients.length - 1]
    if(selectedUser || searchValue || selectedCustomTags !== null){
      if((selectedTab !== 2) && !lastMessageReached){
        setPaginating(true);
        getMoreMessagesFiltered(conversationState.page + 1, lastMessage.id, showUnreads, showArchivedMessages, searchValue, selectedUser, selectedCustomTags, notAssigned, selectedGroup)
        .then(result => {
          setPaginating(false);
          if(result.data.objects.length === 0 || result.data.num_results < num_results){
            setLastMessageReached(true);
          }
        });
      }
      if(selectedTab === 2 && !lastClientReached){
        setPaginating(true);
        getMoreClientsFiltered(searchValue, lastClient.client_id)
        .then(result => {
          if(result.data.objects.length === 0 || result.data.num_results < num_results){
            setLastClientReached(true);
          }
          setPaginating(false);
        });
      }
    } else {
      if((selectedTab !== 2) && !lastMessageReached){
        setPaginating(true);
        getMoreMessagesByContact(conversationState.page + 1, lastMessage.id, showUnreads, showArchivedMessages, notAssigned)
        .then(result => {
          if(result.data.objects.length === 0 || result.data.num_results < num_results){
            setLastMessageReached(true);
          }
          setPaginating(false);
        });
      }
      if(selectedTab === 2 && !lastClientReached){
        setPaginating(true);
        getMoreClients(lastClient.client_id)
        .then(result => {
          if(result.data.objects.length === 0 || result.data.num_results < num_results){
            setLastClientReached(true);
          }
          setPaginating(false);
        });
      }
    }
  }
}

let itemsToShow = (selectedTab !== 2) ? conversationState.conversations : clientsState.showedClients;

if (selectedTab !== 2) {
  itemsToShow = itemsToShow.filter((c) => {
    return filterArchivedMessages ? c.client.archived : !c.client.archived;
  });

  if (filterUnreads) {
    itemsToShow = itemsToShow.filter(c => c.client.unread_messages || c.client.id === conversationActive);
  }
}

function getFilteredConversationsLocal(conversations, { assign, notAssigned, selectedGroup, selectedUser }) {
    let filteredConversations = conversations.filter(conversation => {
    const hasUsername = conversation.client.conversation?.user?.username !== undefined;
    const groupIdMatch = selectedGroup && conversation.client.conversation?.group?.id === selectedGroup;
    const userIdMatch = selectedUser && conversation.client.conversation?.user?.id === selectedUser;
    const bothGroupAndUserSelected = selectedGroup && selectedUser;
    const groupAndUserMatch = bothGroupAndUserSelected ? (groupIdMatch && userIdMatch) : (groupIdMatch || userIdMatch);
    const shouldShowConversation = (!assign && !notAssigned && !selectedGroup && !selectedUser) ||
                                   (assign && hasUsername) ||
                                   (notAssigned && !hasUsername) ||
                                   groupAndUserMatch;

    return shouldShowConversation;
  });
  if (Array.isArray(selectedCustomTags) && selectedCustomTags.length > 0) {
    filteredConversations = filteredConversations.filter(conversation => {
      const clientCustomTagIds = conversation.client.client_custom_tags.map(ct => ct.custom_tag.id);
      return selectedCustomTags.every(tag => clientCustomTagIds.includes(tag));
    });
  }

  return filteredConversations;
}

itemsToShow = getFilteredConversationsLocal(itemsToShow, {
  assign,
  notAssigned,
  selectedGroup,
  selectedUser
});

itemsToShow.map(conversation => {
  let client_custom_tags = conversation.client.client_custom_tags.map(tag => {

    if (message_context.customTags.filter(x => x.id === tag.custom_tag.id)[0] !== undefined){
      let client_tag = {};
      client_tag['id'] = tag.custom_tag.id;
      client_tag['name'] = message_context.customTags.filter(x => x.id === tag.custom_tag.id)[0].name;
      client_tag['color'] = message_context.customTags.filter(x => x.id === tag.custom_tag.id)[0].color;
      client_tag['fixed'] = message_context.customTags.filter(x => x.id === tag.custom_tag.id)[0].fixed;

      return client_tag
    }

  });
  client_custom_tags = client_custom_tags.filter(x => x !== undefined);
  conversation.client.tags = client_custom_tags
  return conversation

});

function orderByPinup(a, b) {
  if (a.client.pinup && !b.client.pinup) return -1;
  else if (!a.client.pinup && b.client.pinup) return 1;
  else return 0;
}

itemsToShow.sort(orderByPinup);

const conversationItems = (
  <div>
    {
      itemsToShow.map((conversation) => {
      if (conversation !== undefined){
        let active = false;
        if (conversation.client.id === conversationActive){
          active = true;
        }
        return (<ConversationListItem key={
          `${selectedTab}
           ${((selectedTab !== 2) && '-' + showArchivedMessages)}-
           ${conversation.client_id}`
        }
          supervisor={supervisor}
          data={conversation}
          selectedTab={selectedTab}
          onClick={() => conversationListItemHandler(conversation)}
          conversationActive={active}
        />)
      }
    })}
  </div>
);

let viewCreateUser = (
  <Dialog header={'Creación de Cliente'} style={{ width: '94vh', height: 'auto' }} visible={showClientView} onHide={() => setShowClientView(false)}>
      <Fragment>
        <ClientFormCusmon/>
      </Fragment>
    </Dialog>
);

const conversationListItemHandler = (conversation) => {
  props.onSelectClientHandler(conversation.client);
  setConversationActive(conversation.client.id);
  if (conversation.client.id !== conversationActive){
    if (player.ref !== null && player.ref !== undefined && !audioEnded && !isMobile()){
      player.hidden = false;
      setIsAudioVisible(true)
      player.play();
    }
  }
}

const handleAudioHide = () => {
  setAudioEnded(true)
  player.hidden = true;
  setIsAudioVisible(false)
  player.pause();
}

let tabOptions =  ['fa-regular fa-inbox', 'fa-regular fa-envelope', 'fa-regular fa-address-book', 'fa-regular fa-box-archive'];

useEffect(() => {
  switch (selectedTab) {
    case 0:
      setArchivedMessages(false);
      setFilterArchivedMessages(false);
      setShowUnreads(null);
      setAssign(null)
      setNotAssigned(null)
      break
    case 1:
      setArchivedMessages(false);
      setFilterArchivedMessages(false);
      setShowUnreads(true);
      setAssign(null)
      setNotAssigned(null)
      break
    case 3:
      setArchivedMessages(true);
      setFilterArchivedMessages(true);
      setShowUnreads(null);
      setAssign(null)
      setNotAssigned(null)
      break
    case 4:
      setArchivedMessages(false);
      setFilterArchivedMessages(false);
      setShowUnreads(null);
      setAssign(true)
      setNotAssigned(null)
      break
    case 5:
      setArchivedMessages(false);
      setFilterArchivedMessages(false);
      setShowUnreads(null);
      setAssign(null)
      setNotAssigned(true)
      break
    default:
      break;
    }
}, [(selectedTab)]);

let render_modal = dialogCustomTag || dialogReassignMessages ? modalInfo : null;

let modal_message_note_reminders = dialogNoteReminder ? <MessageNoteReminder enabled={dialogNoteReminder}
                                                                             setEnabled={setDialogNoteReminder}
                                                                             isGlobal={true}/> : null

let render_note_messages = selectedMessageNote !== null && globalClients ? <MessagesNoteModal
      selectedMessage={selectedMessageNote} selectedClient={selectedClient} contactSelected={selectedContact}
      activeNotes={activeMessageNoteModal} setActiveNotesEnabled={setActiveMessageNoteModal}
      clientFullName={clientFullName}
  /> : null;

const rightToolBarItems = () => {
  let reminder_counter = expirationNotes > 0 ? (
    <span className="fa-layers-counter fa-layers-bottom-right">{expirationNotes}</span>) : null;

  let reminderShakeProp = expirationNotes > 0 ? {'shake': true} : {'shake': false};

  let reminderShakePropMore = !itemMenuMore ? expirationNotes > 0 ? {'shake': true} : {'shake': false} : null;

  let more_items = (
      <ToolbarButton key="more_items"
                     icon={"fa-regular fa-ellipsis-vertical"}
                     style={{width: '2em', height: '2em'}}
                     onClick={() => itemMenuMore ? setItemMenuMore(false) : setItemMenuMore(true)}
                     {...reminderShakePropMore}>
          {!itemMenuMore ? <div style={{position: 'relative', right: '10px'}}>{reminder_counter}</div> : null}
          {itemMenuMore ? <FontAwesomeIcon icon={'times'} size={'1x'} transform={'shrink-1 up-12 right-16'} /> : null}
      </ToolbarButton>
  );

  let add_custom_tags = (
      <ToolbarButton key="filter_custom_tag"
                     name={"Etiquetas"}
                     icon={"fa-regular fa-tag"}
                     style={{width: '2em', height: '2em'}}
                     onClick={() => dialogCustomTags('list', null)}>
      </ToolbarButton>
  );

  let add_user = (
    <ToolbarButton key="create_user"
                   name={"Usuario"}
                   icon={"fa-regular fa-user"}
                   style={{width: '2em', height: '2em'}}
                   onClick={() => setShowClientView(true)}>
    </ToolbarButton>
  );

  let transfer = ['admin', 'supervisor'].includes(switchRole()) ? [
      <ToolbarButton key="transfer"
                     name={"Transferir Chats"}
                     icon={"fa-regular fa-exchange-alt"}
                     style={{width: '2em', height: '2em'}}
                     onClick={() => dialogReassignConversation('reassign_messages', null)}/>
  ] : [];

  let reminders = (
      <ToolbarButton key="reminders"
                     name="Recordatorios"
                     icon={"fa-regular fa-calendar-check"}
                     size={"xs"}
                     style={{width: '2em', height: '2em'}}
                     onClick={() => showMessageNoteReminder()}
                     {...reminderShakeProp}>
          <div style={{position: 'relative', right: '5px'}}>{reminder_counter}</div>
      </ToolbarButton>
    );
  if (itemMenuMore) return [more_items, transfer, reminders, add_custom_tags, add_user]
  else return [more_items]

};

const showMessageNoteReminder = () => {
  setDialogNoteReminder(true);
}

const searchConversationContactHandler = (e) => {
  setLoadingSearchConversationContact(true)
  const CancelTokenFilterRequest = axios.CancelToken;
  const conversationFilterRequest = CancelTokenFilterRequest.source();
  getClientsFilteredDebounced.current(searchConversationContactValue.phone_number, selectedCustomTags, selectedUser, selectedGroup,
      conversationFilterRequest, {...clientsState, page: 1}, notAssigned);
}

const conversationScroll = (
  <InfiniteScroll
    pageStart={0}
    initialLoad={false}
    loadMore={handleScroll}
    hasMore={true}
    useWindow={false}
    getScrollParent={() => props.parentRef.current}
    loader={loadinglist ? <ConversationSkeleton count={5}/> : null}
  >
    {conversationItems}
  </InfiniteScroll>
)

const conversationQuick = (
  selectedTab === 2 ? (
    <div style={{width: isMobile() ? height - 20 : 'auto'}} className={'init-conversation-main'}>
      <p className={'toolbar-title mt-3'} style={{textAlign: 'center'}}>Inicio de conversación rápida</p>
      <div className='init-conversation'>
        <ConversationContact
            placeholder={'Iniciar Conversacion'}
            onChange={(value) => setSearchConversationContactValue(value)}
            value={searchConversationContactValue}/>
        <span data-tip={'Iniciar Conversación Rápida'}>
          <FontAwesomeIcon onClick={(e) => searchConversationContactHandler(e)}
                           icon={"fa-regular fa-paper-plane"} className={`init-conversation-icon btn-actions-default init-conversation-icon-${searchConversationContactValue.phone_valid}`}/>
        </span>
      </div>
      {searchConversationContactValue.phone_number !== '' ? (!searchConversationContactValue.phone_valid ? (
        <div className={'conversation-contact-phone-invalid'}>
            Numero inválido
        </div>
        ) : null) : null }
    </div>
    ) : null
)

const allTagFilter = (
  (assignedUserFilter || notAssignedUserFilter || userFilter.length > 0 || tagFilter.length > 0 || groupFilter.length > 0) && (
    <div className={`user-tag-filter mt-auto mb-4`}>
      {assignedUserFilter}
      {notAssignedUserFilter}
      {userFilter}
      {tagFilter}
      {groupFilter}
    </div>
  )
);

const conversationView = (
  <div className='scrollable' ref={props.parentRef}>
    <div className='conversation-list'>
      {allTagFilter ? allTagFilter : conversationQuick}
      {!loadingsearch ? conversationScroll : <ConversationSkeleton count={10}/>}
    </div>
  </div>
);

const conversationViewMobile = (
  <div className='conversation-list' style={{width: isMobile() ? height - 20 : 'auto'}}>
    {allTagFilter ? allTagFilter : conversationQuick}
    {!loadingsearch ? conversationScroll : <ConversationSkeleton count={5}/>}
  </div>
);

const toolbar = (
  <div>
    <Toolbar
        rightItems={rightToolBarItems()}
        hideShowLeftItems={false}
        leftItems={<ChannelSelector/>}
        className={"toolbar toolbar-custom"}>
    </Toolbar>
    <CustomTabHeaders fontAwesomeIcon={true} headers={tabOptions} selectedIndex={selectedTab} onChange={setSelectedTab}/>
  </div>
);

const searchInput = (
    <div className='search-filter'>
      {showConversationListGroups || showConversationListUsers || showConversationListTags ? null : <ConversationSearch className='conversation-search' onClearValue={onClearValue} onChange={onChangeFilterHandler} value={searchValue} loading={loadingsearch}/>}
      {userListDropDown}
      {tagListDropDown}
      {groupListDropDown}
      <div data-tip={!isMobile() ? 'Filtro' : null} >
        <FontAwesomeIcon
          onClick={(event) => {
            showConversationListTags ? setShowConversationListTags(null) : showConversationListUsers ? setShowConversationListUsers(null) : showConversationListGroups ? setShowConversationListGroups(null) : refMenu.current.toggle(event)
          }}
          icon={(showConversationListTags || showConversationListUsers || showConversationListGroups) ? "times" : "fa-regular fa-filter"}
          className={"btn-actions btn-actions-default btn-fontawesome"}
          style={{cursor: 'pointer', width: '1.5em', height: '1.4em'}}/>
      </div>
    </div>
)

const audioCurrent = (
  <div className={isAudioVisible ? "audio-player-visible" : "audio-player-hidden"}>
      {isAudioVisible ? (
        <div className="audio-info-container">
          <button onClick={() => handleAudioHide()}
            type="button"
            class="p-dialog-header-icon p-dialog-header-close-audio p-link"
            aria-label="Close">
              <span class="p-dialog-header-close-icon pi pi-times"/>
          </button>
          <Avatar
                  maxInitials={1}
                  name={player ? player.name : 'Cliente'}
                  round={true}
                  size="60"/>
          <div className="conversation-title">
            <h1 className="conversation-list-item-name">
                {player ? player.name : 'Cliente'}
            </h1>
          </div>
        </div>
      ) : null}
      <audio id="globalAudioPlayer" className='audio-info-container' controls hidden controlsList='nodownload noplaybackrate'/>
  </div>
);

const menuFilter = (
  <div>
    <Menu className='menu-filter' model={itemMenuFilter} popup ref={refMenu}/>
  </div>
);

const conversationUp = (
  <div style={{width: isMobile() ? height - 20 : 'auto'}}>
    {toolbar}
    {searchInput}
    {menuFilter}
  </div>
)
  return (
    <div className='messenger-conversation-list-container'>
      {conversationUp}
      <div className='content'>
        {audioCurrent}
        <Toast ref={toast}/>
        {isMobile() ? conversationViewMobile : conversationView}
        {!loadingsearch ? loading ? null : itemsToShow.length === 0 ? <ResultNotFount/> : null : null}
        {render_modal}
        {modal_message_note_reminders}
        {render_note_messages}
        {loadingConversation ? <CustomSpinner status={'Obteniendo conversaciónes...'} style={{paddingLeft: '22%'}}/> : null }
        {loadingConversationQuick ? <CustomSpinner status={'Iniciando conversaciónes rápida...'}/> : null}
        {viewCreateUser}
      </div>
    </div>
  );
});

export default ConversationList